.company-name {
    display: flex;
    align-items: center;
    gap: 10px;
}

.company-name span {
    display: block;
}

.company-name .avatar {
    width: 30px;
    height: 30px;
    border-radius: 6px;
    background-color: #ccc;
    overflow: hidden;
}

.company-name .avatar img {
    width: 100%;
}
