.page-subtitle {
    letter-spacing: unset !important;
}

.page-subtitle .route-extra {
    color: rgb(150, 149, 149);
}

.page-subtitle .route-main {
    color: rgb(0, 0, 0);
    font-weight: 500;
}

.page-title {
    font-weight: 700 !important;
    color: #0c415b;
}
