* {
    padding: 0;
    margin: 0;
    font-family: 'Work Sans', sans-serif;
    box-sizing: border-box;
}

body {
    background-color: #eff0f0 !important;
}
