body {
    font-weight: 400;
    font-family: 'Work Sans', sans-serif !important;
}

.w-40 {
    width: 40% !important;
    min-width: 40% !important;
}
.w-100 {
    width: 100% !important;
}
.payrollCart {
    overflow-x: scroll;
}
.table-container .item {
    margin-top: 1rem;
    padding-bottom: 0.25rem;
    border-bottom: 1px solid black;
}
.table-container .content {
    text-align: right;
    width: 55%;
    max-width: 55%;
    word-wrap: break-word;
}
/* sidebar custom */
.custom_main-sidebar {
    background-color: #0c415b;
    border-radius: 0 20px 20px 0;
}
.white_icon {
    color: #fff;
    font-size: 18px;
    position: relative;
    top: 3px;
}
.main-sidebar .nav-wrapper .nav {
    padding: 0 15px;
}
.main-sidebar .nav .nav-item,
.main-sidebar .nav .nav-link {
    border-radius: 10px;
    margin-top: 4px;
    background-color: transparent;
}
.main-sidebar .nav .nav-item span,
.main-sidebar .nav .nav-link span {
    color: #fff;
    font-size: 18px;
}
.main-sidebar .nav .nav-item .nav-link.active,
.main-sidebar .nav .nav-item .nav-link:hover,
.main-sidebar .nav .nav-item.active {
    background-color: #ffd96c;
}
.main-sidebar .nav .nav-item:hover {
    background-color: transparent;
}
.main-sidebar .nav .nav-item .sub-menu,
.main-sidebar .nav .nav-item .sub-menu,
.main-sidebar .nav .nav-item .sub-menu,
.main-sidebar .nav .nav-item .sub-menu {
    background-color: transparent;
}
.main-sidebar .nav .nav-item .nav-link.active span,
.main-sidebar .nav .nav-item .nav-link:hover span,
.main-sidebar .nav .nav-item.active span,
.main-sidebar .nav .nav-item:hover span {
    color: #0c415b;
}
.main-sidebar .nav .nav-link {
    position: relative;
}
.main-sidebar .nav .nav-item span.sub_icon,
.main-sidebar .nav .nav-link span.sub_icon {
    position: absolute;
    top: 0;
    right: 0;
    /* width: 100%; */
    /* height: 100%; */
    /* left: 20px; */
    padding: 20px 10px 20px 250px;
}
.main-sidebar .nav .nav-item .nav-link.active,
.main-sidebar .nav .nav-item .nav-link:hover,
.main-sidebar .nav .nav-item.active,
.main-sidebar .nav .nav-item:hover {
    box-shadow: none !important;
}

.css-konndc-MuiListItemText-root {
    margin-top: 15px !important;
}
.css-10hburv-MuiTypography-root {
    font-weight: 600 !important;
    color: #000;
    font-family: 'Work Sans', sans-serif !important;
}
.css-1phucj-MuiTypography-root {
    letter-spacing: 1.1px !important;
    text-transform: capitalize;
}
.main-sidebar .nav .sub-menu {
    /* display: none; */
    padding-left: 20px;
    margin-top: 5px;
}
.card-header:first-child {
    border-radius: 4px 4px 0 0 !important;
}
.card {
    border-radius: 4px !important;
}
.main-sidebar .nav .sub-menu li {
    list-style: none;
    border-radius: 10px;
    margin-top: 4px;
    background-color: transparent;
    white-space: nowrap;
    min-width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    will-change: background-color, box-shadow, color;
    -webkit-transition: box-shadow 0.2s ease, color 0.2s ease, background-color 0.2s ease;
    transition: box-shadow 0.2s ease, color 0.2s ease, background-color 0.2s ease;
    font-size: 0.85rem;
    min-height: 50px;
    /* line-height: 50px; */
}

.main-sidebar .nav .sub-menu li a {
    font-weight: 400;
    padding: 0.9375rem 1.5625rem;
    color: #fff;
    font-size: 17px;
}

/* logo custom */
.ct_name-logo {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    letter-spacing: 1.5px;
}

/* navbar custom */
.main-navbar {
    position: relative;
    z-index: 3;
    background-color: #0c415b;
}
.main-navbar .navbar .notifications .badge {
    top: 0;
}
.ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
}
/* .custom_nav {
    left: 0;
    margin-bottom: ;
} */

/* form login */
.content-login {
    padding: 80px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.content-login .wrapper {
    width: 100%;
}
.content-login .wrapper .avt_prof {
    width: 100%;
    position: relative;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.content-login .wrapper .avt_prof .avt {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #0c415b;
    margin-bottom: 30px;
}
.content-login .wrapper .avt_prof p {
    font-size: 25px;
    font-weight: bold;
}
.content-login .wrapper .login_link {
    margin: 15px 0;
    display: flex;
    gap: 20px;
    justify-content: space-around;
    align-items: center;
}
.content-login .wrapper .login_link div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    width: 100%;
    font-size: 14px;
    color: #000;
    border-radius: 5px;
    background-color: #f7f7f8;
    cursor: pointer;
}

.content-login .wrapper .login_link div span {
    font-family: 'Nunito', sans-serif;
    display: block;
}

.content-login .wrapper .or {
    width: 100%;
    font-weight: 500;
    margin: 20px 0 25px 0;
    text-align: center;
    position: relative;
}

.content-login .wrapper .or span {
    width: 20px;
    padding: 0 10px;
    text-align: center;
    background-color: #fff;
    position: relative;
    z-index: 2;
}

.content-login .wrapper .or::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 10px;
    width: 100%;
    border-bottom: 1px solid #030229;
    z-index: 1;
    opacity: 0.1;
}
.content-login .wrapper .footer_form {
    display: flex;
    justify-content: space-between;
}
.content-login .wrapper .footer_form a {
    margin-top: 4px;
    height: 50px;
    font-size: 16px;
    color: #0c415b;
}
.content-login .wrapper .login_link div img {
    width: 20px;
    margin-right: 8px;
}

/* dashboard custom */

.op_one span {
    position: absolute;
    top: 50%;
    left: -30px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #0c415b;
    transform: translateY(-50%);
}
.op_two span {
    position: absolute;
    top: 50%;
    left: -30px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ffd96c;
    transform: translateY(-50%);
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none !important;
}
.top_picker {
    padding: 25px 30px !important;
    background-color: #0c415b;
}
.top_picker label {
    color: #fff !important;
    font-size: 18px;
}
.top_picker button {
    color: #fff !important;
}
.top_picker .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
}
.title-date span {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
}
.title-date span:last-child {
    display: block;
    color: #fff;
    font-weight: 300;
    font-size: 18px;
}
.custom_top {
    height: 57px !important;
}

.container_chart {
    height: 460px !important;
}
.container_chart .chart_wwrapper {
    height: 400px;
}

/* companies custom */
.btn-other-pr-save {
    margin-top: 20px;
    width: 100%;
}
.button__print_download_payroll {
    display: flex;
    flex-wrap: wrap;
    max-width: 210px;
    min-width: 210px;
    justify-content: space-around;
}

.button__chooseList {
    border: 2px solid #0c415b;
    color: #0c415b !important;
    display: flex;
    width: 100%;
    padding: 0.4rem 0;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    background-color: #fff !important;
    cursor: pointer;
}
.button__training {
    border: 2px solid #ffd96c;
    color: #0c415b !important;
    display: flex;
    width: 100%;
    padding: 0.4rem 0;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    background-color: #ffd96c !important;
    cursor: pointer;
}
.sub-menu-item {
    width: 100px !important;
}
.fix-blue-color {
    color: black;
}
.fix-blue-color:hover {
    color: #1890ff;
}
.button__warning,
.btn-save {
    cursor: pointer;
    background: #ffd96c;
    color: #0c415b;
    font-weight: 500;
}
.button__warning:hover,
.btn-save:hover {
    color: #0c415b !important;
    opacity: 0.6;
    transition: 0.8s;
}
.btn-save-attendanceSetting {
    margin-top: 1% !important;
}

.button_delete {
    border: 2px solid #ff6863;
    color: #ff6863 !important;
    display: flex;
    width: 100%;
    padding: 0.4rem 0;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    background-color: #fff !important;
    cursor: pointer;
}
.button_delete:hover {
    background-color: rgba(255, 104, 99, 0.1) !important;
    transition: 0.8s;
}
.button_SCV {
    border: 1px solid #ff6863;
    color: #0c415b !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .cutom_icondelete {
    width: 35px !important;
    height: 35px !important;
    background-color: #fff !important;
} */

.button__search {
    cursor: pointer;
    background: #ffd96c;
    color: #0c415b;
    font-weight: 600;
    height: 35px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: 0;
    border-radius: 5px;
    border: none;
}
.button__search:hover {
    opacity: 0.6;
    transition: 0.9s;
}

.pointer {
    cursor: pointer;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
    font-weight: 600 !important;
    font-size: 16px !important;
}

/* form */

.css-17jjc08-MuiDataGrid-footerContainer {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    position: relative !important;
}
.css-levciy-MuiTablePagination-displayedRows {
    margin: 0 !important;
}
.css-rtrcn9-MuiTablePagination-root:last-child {
    position: relative !important;
}
.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    color: #fff;
    background-color: #0c415b !important;
    border-radius: 6px !important;
}
.gr_icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon_delete {
    color: #ff6863;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: rgba(225, 221, 221, 0.562);
    display: grid;
    place-items: center;
    cursor: pointer;
}
.icon_edit {
    color: #0c415b;
    margin: 0 5px;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: rgba(225, 221, 221, 0.604);
    display: grid;
    place-items: center;
    cursor: pointer;
}
.icon_eye {
    color: #0c415b;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: rgba(225, 221, 221, 0.649);
    display: grid;
    place-items: center;
    cursor: pointer;
}

/* modal */

.modal {
    font-size: 12px;
}
.modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}
.modal > .content {
    width: 100%;
    padding: 10px 5px;
}
.modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}
.modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}

/* tab */

/* .css-1gsv261 {
    border-bottom: none !important;
}
.MuiTabs-indicator {
    top: 0 !important;
} */
.slide .MuiSvgIcon-root {
}
.MuiButtonBase-root.slide {
    text-transform: none !important;
    color: #aaaaaa;
    width: 200px;
}
.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
}
.ant-table-wrapper {
    width: 100% !important;
}
.ant-table-pagination-right {
    justify-content: center !important;
}
.ant-pagination-item-active {
    background-color: #0c415b;
    border: none !important;
    border-radius: 2px;
}
.ant-pagination-item-active a {
    color: #ffffff !important;
}
.collection-create-form_last-form-item {
    margin-bottom: 0;
}
.MuiButtonBase-root.slide {
    width: 10%;
    transition: all 0.2s linear !important;
    border: 1px solid #aaaaaa3c !important;
    color: #0c415b !important;
}

.MuiButtonBase-root.slide-info {
    width: calc(100% / 8) !important;
}
/* .css-1t4lqmc-MuiButtonBase-root-MuiTab-root:nth-child(1) {
    width: 15%;
} */
.slide.Mui-selected {
    background-color: #0c415b !important;
    color: #ffffff !important;
    width: 20%;
}
.MuiTabs-flexContainer {
    height: 70px !important;
}
/* ======================================================= */
.ant-table-thead > tr > th {
    font-weight: 600 !important;
}
.reject_btn {
    cursor: pointer;
    color: #f94943;
    font-size: 15px;
    border-right: 1px solid rgba(85, 85, 85, 0.397);
    padding-right: 10px;
}

.reject_btn:hover {
    color: #0c415b;
}
.approve_btn {
    cursor: pointer;
}
.approve_btn:hover {
    color: #3002fa;
}
.title_name {
    text-align: left;
}
.title_name.expence-claim {
    height: 100%;
    display: flex;
    align-items: center;
}
.title_name.expence-claim p {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}
.title_name.expence-claim p:first-child {
    font-weight: 600;
    font-size: 20px;
}
.title_name.expence-claim p:last-child {
    font-size: 15px;
    color: rgb(172, 172, 172);
}
/* .pickerAtt {
    width: 500px;
    height: 80px !important;
    background-color: #dbeaf1;
} */
.gr_note {
    position: relative;
    overflow-x: scroll;
}
.gr_note ul {
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 20px 0;
}
.gr_note ul li {
    list-style: none;
    margin-left: 22px;
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;
}
.gr_note ul li p {
    font-size: 14px;
    padding-left: 10px;
    margin: 0;
}
span.half_day {
    color: #0c415b;
}
span.full_day {
    color: #63dd58;
}

span.absence_day {
    color: #ff6863;
}
span.medical_services {
    color: #0b26f4;
}
span.school {
    color: #0e9657;
}
span.time_to_leave {
    color: #8f0876;
}
.ant-list-item {
    padding: 10px 0;
}
.ant-list-item-meta-title {
    font-size: 20px;
    text-transform: capitalize;
}
.ant-list-split .ant-list-item {
    border-bottom: 1px solid #d7d3d3 !important;
}
.ant-list-split .ant-list-item:last-child {
    border-bottom: 1px solid #d7d3d3 !important;
}
.ant-list-item-meta-description {
    font-size: 14px;

    text-transform: uppercase;
}
.demo-loadmore-list {
    min-height: 350px;
}
span.fs_18 {
    font-size: 18px !important;
}
.grid_center {
    flex: 1;
    display: grid;
    place-items: center;
}

.ant-tabs-tab-active {
    background-color: #dbeaf1;
    border-left: 3px solid #0c415b;
}
.ant-tabs-ink-bar {
    background-color: #0c415b !important;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar {
    left: 0 !important;
}
.ant-tabs-nav {
    width: 333px;
}

.list_admin {
    margin: 0;
    padding: 0;
}
.list_admin li {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 17px 0;
}
.padding_100 {
    padding-right: 100px;
}
.icon_localstaf {
    border: 1px solid #ff6863;
    border-radius: 4px !important;
    margin-top: 20px;
    padding: 4px 0;
    cursor: pointer;
    display: grid;
    place-items: center;
}
.icon_localstaf span {
    color: #f94943;
}
.icon_add_localstaf {
    width: 95px;
    margin-top: 15px;
    cursor: pointer;
    display: grid;
    place-items: center;
    padding: 4px;
    margin-right: 18px;
    background-color: #ffd96c;
    padding: 6px;
    border-radius: 4px;
}
.container_appro {
    width: 60%;
    margin: 0 auto;
}
span.public_day {
    color: #ffd96c;
}
.line {
    height: 2px;
    background-color: transparent;
    border-bottom: 2px solid #0c415b;

    margin: 15px 70px;
}
.line2 {
    height: 2px;
    background-color: transparent;
    border-bottom: 2px solid #0c415b;
    width: 70%;
    margin-top: 70px;
}

/* reponsive   */
/* table {
  width: 100%;
  border-collapse: collapse;
}

tr:nth-of-type(odd) {
  background: #eee;
}
th {
  background: #333;
  color: white;
  font-weight: bold;
}
td,
th {
  padding: 6px;
  border: 1px solid #ccc;
  text-align: left;
} */

.image_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    background-color: #ccc;
    margin: 0 auto;
    border-radius: 50% !important;
    overflow: hidden;
}
.image_box img {
    width: 100%;
    height: auto;
}

.input_avt {
    position: absolute;
    width: 20%;
    height: 20%;
    top: 60%;
    left: 60%;
    cursor: pointer !important;
    transition: transform(-50%, -50%);
}
.input_avt input {
    opacity: 0;
}
.input_avt_profile input,
.input_avt_attachments input {
    display: none !important;
}
.input_avt img {
    position: absolute;
    top: 10px;
    right: 40px;
    cursor: pointer !important;
}
.box_image {
    width: 150px;
    height: 150px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.content_td_mobile .title_mobile,
.content_td_mobile_db .title_mobile {
    display: none;
}
.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
    background: transparent;
}
.content_ {
    display: flex;
    align-items: center;
}
.content_ .img_content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3002fa;
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    border: 1px solid #d7d1d1;
    border-radius: 6px;
    overflow: hidden;
}
.content_ .img_content img {
    width: 100%;
    object-fit: cover;
}
.content_ .title_content {
    margin-left: 20px;
    line-height: 10px;
}
.content_ .title_content h5 {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: 'Work Sans', sans-serif !important;
    color: #fff;
}
.content_ .title_content span {
    display: block;
    color: #fff;
    line-height: 30px;
    font-size: 16px;
}
.content_ .title_content span:first-child {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
}
.statistic-title span {
    display: block;
    color: #0c415b;
    position: relative;
    font-size: 18px;
}
.statistic-title span:last-child {
    font-size: 20px;
    font-weight: bold;
}
.mt-3 {
    margin: 0px !important;
}
.ct-table-data {
    overflow-x: scroll;
}
@media screen and (max-width: 768px) {
    .ct-table-data .ant-table-row td {
        display: flex !important;
        flex-direction: column !important;
    }
    .ant-table-tbody tr:nth-child(even) {
        background-color: rgba(202, 217, 249, 0.773) !important;
    }

    .ant-table-tbody tr:nth-child(odd) {
        background-color: rgba(244, 247, 253, 0.773) !important;
    }

    .ct-table-data thead th:not(:first-child) {
        display: none !important;
    }

    .ct-table-data td,
    .ct-table-data th {
        display: block;
    }

    .ant-table-tbody > tr > td {
        padding: 5px 16px;
    }

    .content_td_mobile,
    .content_td_mobile_db {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .content_td_mobile .title_mobile,
    .content_td_mobile_db .title_mobile {
        font-weight: 600;
        display: block;
    }

    /* .pickerAtt {
        width: 100% !important;
    } */
    .btn_search-mb {
        width: 100%;
        margin-top: 10px;
        margin-right: auto;
    }

    .ant-tabs-nav {
        width: 90px;
        margin-left: -12px;
    }
    .padding_100 {
        padding: 0;
    }
    .bg_login {
        display: none;
    }
    .button_companies span.text,
    .button_department span.text {
        display: none;
    }
    .content-login {
        padding: 20px;
    }
    .mb-mbile {
        margin-bottom: 20px;
    }
    .form-group-mg {
        margin: 10px 0;
    }
}

/* css title input */
.title {
    display: block;
    font-weight: 500;
    font-size: unset !important;
    color: #000 !important;
}
.title span {
    color: red;
}

/* general styling */

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 601px) and (max-width: 768px) {
    .form-group-mg {
        margin: 10px 0;
    }
    .mb-mbile {
        margin-bottom: 20px;
    }
    .ant-table-container .ant-table-content {
        overflow-x: auto;
    }
    .ant-tabs-nav {
        width: 103px;
    }
    .padding_100 {
        padding: 0;
    }
    .button_companies {
        font-size: 15px;
        color: #0c415b !important;

        overflow: hidden;
    }
    .bg_login {
        display: none;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 769px) and (max-width: 992px) {
    .content-login {
        padding: 80px;
    }
    .ant-table-container .ant-table-content {
        overflow-x: auto;
    }
    .ant-tabs-nav {
        width: 140px;
    }
    .padding_100 {
        padding: 0;
    }
    .button_companies {
        font-size: 15px;
        color: #0c415b !important;

        overflow: hidden;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
    .ant-table-container .ant-table-content {
        overflow-x: auto;
    }

    .ant-tabs-nav {
        width: 203px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {
} */

.button_companies {
    font-size: 15px;
    color: #0c415b !important;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-modal-wrap {
    z-index: 999999;
}
.css-jpln7h-MuiTabs-scroller {
    overflow-x: auto !important;
    /* padding-bottom: 10px; */
}
.MuiTabs-scroller {
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    margin-bottom: 0px !important;
}
.h-60 {
    height: 60px;
}

.main-sidebar {
    z-index: 997 !important;
    border-radius: 0 24px 0 0;
}
.btn_user-action {
    background-color: #fff;
    border-color: #fff;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn_user-action span {
    color: #000;
    font-weight: 500;
}
.btn_user-action img.user_avt {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 10px;
}
.content_name {
    display: flex;
    flex-direction: column;
}
.content_name span {
    font-size: 12px;
    font-weight: normal;
    text-transform: capitalize;
    width: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.custom_username {
    width: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
}
.content_item {
    width: 65px;
    cursor: pointer;
}
.nav-link-icon__wrapper {
    position: relative;
}
.nav-link-icon__wrapper .note {
    position: absolute;
    top: 20%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #aaaaaa;
}
.page-subtitle {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #080e21 !important;
}
.ct-dasbroad {
    display: block;
    font-size: 18px;
    font-weight: 500;
    color: #0c415b !important;
    margin-bottom: 0 !important;
}
.ct-title {
    display: block;
    font-size: 18px;
    font-weight: 600;
    color: #000 !important;
    margin-bottom: 0 !important;
}

.ct-number {
    color: #0c415b !important;
    font-size: 28px !important;
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
}
.ct-number-small {
    color: #0c415b !important;
    font-size: 20px !important;
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
}
.bg-warning {
    background-color: #ffd96c !important;
    border-color: none !important;
}
.bg-warning p {
    margin-bottom: 0;
    color: #0c415b;
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
}
.ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    height: 2.6em !important;
}
.content_td_mobile p {
    margin-bottom: 0 !important;
    text-transform: capitalize;
    width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.content_td_mobile_db p {
    margin-bottom: 0 !important;
    text-transform: capitalize;
    width: 70px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.ant-select-arrow {
    font-size: 10px;
    /* left: 70px; */
}
.avt_leave_requ {
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 12px;
    border: 1px solid rgba(210, 213, 220, 0.5);
    overflow: hidden;
}
.avt_leave_requ img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.box-name {
    display: flex;
    align-items: center;
    gap: 10px;
}

.ant-spin-container {
    padding: 15px 15px 0;
}

.title__header {
    padding: 15px 30px;
    border-bottom: 1px solid #ccc;
}

.title__h5 {
    display: block;
    font-size: 18px;
    font-weight: 700;
    color: #080e21 !important;
}

.select-container {
    border: 1px solid #ccc;
    border-radius: 5px;
}

.leave-setting .sl-setting {
    width: 100% !important;
}

/* attendance */
.add-attendence-box {
    width: 50%;
    margin: 0 auto;
}
.calender-box {
    width: 100% !important;
    height: 90px;
    padding: 10px 50px;
    background-color: #dbeaf1;
    border: 2px solid #0c415b;
    border-radius: 5px;
}

.calender-box .date-input span:first-child {
    display: block;
    font-size: 18px;
    font-weight: 600;
}

.calender-box .date-input span:last-child {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: rgb(121, 121, 121);
}

@media (min-width: 375px) and (max-width: 768px) {
    .add-attendence-box {
        width: 80%;
    }
}
/* BUTTON */
.btn-yeallow,
.btn-yeallow-block {
    font-weight: 600;
    background-color: #ffd96c !important;
    border-radius: 5px !important;
    color: #0c415b !important;
}
.btn-yeallow-block {
    display: block;
    margin-right: 0 !important;
    margin-left: auto !important;
}

/* TABS */

.tab-box {
}
.tab-title {
    text-transform: capitalize !important;
    padding: 0 40px !important;
}
.MuiTabs-flexContainer {
    border-top: 3.5px solid #ccc !important;
}
.MuiTabs-indicator {
    top: 0 !important;
    height: 3.5px !important;
    background-color: #0c415b !important;
}
.MuiButtonBase-root {
    color: #ccc !important;
    font-weight: 400 !important;
    font-family: 'Work Sans' !important;
    font-size: 15px !important;
}
.Mui-selected {
    color: #0c415b !important;
    font-weight: 500 !important;
}

/* FORM */
.ant-input,
.ant-select-selector,
.ant-picker {
    width: 100% !important;
    border-radius: 5px !important;
    height: 35px !important;
}
.ant-input.ct-textarea {
    height: auto !important;
}
.ant-select-selector {
    display: flex;
    align-items: center;
}
.password {
    position: relative;
}
.adminEditPassword {
    width: 100%;
    display: inline-flex;
    justify-content: center;
}
.edit-password {
    width: 28%;
    min-width: 170px;
    margin-bottom: 0px;
    display: flex;
    justify-content: space-around;
}
.form-edit-password {
    width: 100%;
    margin-bottom: 4px;
}
@media (max-width: 1050px) {
    .adminEditPassword {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .form-edit-password {
        width: 100%;
        margin-bottom: 4px;
    }
}
.eye-password {
    position: absolute;
    top: 83%;
    transform: translateY(-80%);
    right: 10px;
    cursor: pointer;
}
.eye-password2 {
    top: 70%;
}
.img_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 10px;
    background-color: #ccc;
    overflow: hidden;
}
.img_box img {
    width: 100%;
}
.avatar_wrap p {
    width: 100%;
    margin-top: 5px;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    color: #0c415b;
}
.avatar_wrap .img_avatar {
    display: none;
}
/* statistical */

.statistical span {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #0c415b;
}
.statistical span:last-child {
    font-size: 22px;
    font-weight: 600;
}

.statistical.color__red span {
    color: #ff6863 !important;
}

.text__red {
    color: #ff6863;
}

/* ROW VS COLLUM */
.ct-box {
    margin-top: 40px;
    padding: 0 15px;
}

/* Title Tabs */
.title_tabs {
    margin-bottom: 0 !important;
    margin-top: 15px !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    color: #0c415b;
}

.access_box,
.salary_box {
    border-radius: 6px;
    border: 1px solid #cddc39;
    background-color: #ffffd4;
    padding: 15px;
    font-size: 14px;
}

.ct_btn {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #000;
    font-weight: 500;
}

.ct_btn.cancel {
    border: 1px solid #ff6863;
    color: #ff6863;
    background-color: #fff;
}

.ct_btn.save {
    border: 1px solid #0c415b;
    color: #0c415b;
    background-color: #fff;
}
.ct_btn.disable {
    cursor: not-allowed;
}

.ct_btn.next {
    background-color: #ffd96c;
    color: #0c415b;
}
.ct_btn.none-border {
    border: none;
}
.ct_btn.btn-middle {
    display: block;
    margin: 0 auto;
}

.ct_btn.previous {
    color: #0c415b;
    background-color: #ffd96c;
}

/* Notification */
.notification-box {
    max-height: 20rem;
    overflow: scroll;
}

.percent-dashboard {
    z-index: 0 !important;
}

/* payroll settings */
.title.color-bluedark {
    color: #0c415b !important;
}

.title-font {
    margin-bottom: 0;
    font-family: 'Work Sans' !important;
    font-weight: 600;
}
.text-small {
    display: block;
    font-weight: unset;
    color: rgb(183, 183, 183);
}
.text.bold {
    font-weight: 500;
}
.ant-switch-checked {
    background-color: #0c415b !important;
}

.icon-delete-list {
    border: 1px solid #ff6863;
    border-radius: 4px !important;

    padding: 4px 0;
    cursor: pointer;
    display: grid;
    place-items: center;
}

.icon-delete-list span {
    color: #f94943;
}

.list-item {
    padding-left: 0 !important;
}

.list-btn {
    padding-right: 0 !important;
}

.text-leave-setting {
    color: #0c415b;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
}

/* .btn-add-leave-type {
    background: #0c415b;
    color: white;
} */

.text-leave-type {
    color: #0c415b;
    font-weight: 400;
    margin-left: 4rem;
}

.ant-alert-info .ant-alert-icon {
    color: #0c415b !important;
}

.btn-add-leave-setting {
    background-color: #ffd96c !important;
    border-radius: 0.25rem;
    color: #0c415b !important;
}

/* Filter */
.ct-filter {
    width: 90%;
}

.text-black {
    color: #000 !important;
}

.text-icon-fb {
    color: #000;
    padding-left: 0.3rem;
}

.text-leave-setting {
    color: #0c415b;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
}

.text-leave-type {
    color: #0c415b;
    font-weight: 400;
    margin-left: 4rem;
}

.ant-alert-info .ant-alert-icon {
    color: #0c415b !important;
}

.btn-add-leave-setting {
    background-color: #ffd96c !important;
    border-radius: 0.25rem;
    color: #0c415b !important;
}

.filter-box {
    display: flex;
    gap: 20px;
}

/* OTHER */
.ct-new-job {
    position: absolute;
    right: 45px;
    top: 53%;
    cursor: pointer;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0c415b !important;
}
.title-tab {
    color: #0c415b !important;
}

.dropdown-user .dropdown-item {
    font-size: 0.82rem;
    padding: 6px 14px;
}

/* .modalStyle .ant-modal-header {
    border-radius: 20px 20px 0 0;
}

.newStyle {
    border-radius: 20px;
} */

.ant-modal-content {
    color: #080e21;
}

.ant-modal-footer .ant-btn-primary {
    color: #0c415b !important;
    background: #ffd96c !important;
    border-radius: 0.25rem !important;
    width: 5rem !important;
}

.ant-modal-footer .ant-btn-default {
    color: #0c415b !important;
    border-radius: 0.25rem !important;
    width: 5rem !important;
}

.title-modal-custom {
    display: inline;
    margin-left: 0.75rem;
}

/* ==== PUBLIC HOLIDAY ==== */
.form-add-holiday > div {
    margin-top: 15px;
}

.link {
    cursor: pointer;
    opacity: 0.9;
}

.link:hover {
    opacity: 1;
}

.edit-cancel {
    color: rgb(255, 39, 39);
}

.edit-save {
    margin-left: 10px;
    color: #030229 !important;
}
